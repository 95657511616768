import axios from "axios";

export const state = () => ({
    mix_article_data: [],
    article_cursor: "",
    is_loading: false
})
export const mutations = {
    setMixArticleData(state, data) {
        state.mix_article_data = data;
    },
    setMoreMixArticleData(state, data) {
        state.mix_article_data = state.mix_article_data.concat(data);
    },
    setArticleCursor(state, id) {
        state.article_cursor = id;
    },
    setLoadingStatus(state, is_loading) {
        state.is_loading = is_loading;
    }
}
export const actions = {
    // 暫時沒用
    // shuffleArray(_, array) {
    //     for (let i = array.length - 1; i > 0; i--) {
    //         const j = Math.floor(Math.random() * (i + 1));
    //         [array[i], array[j]] = [array[j], array[i]];
    //     }
    //     return array;
    // },
    async getMixArticleData({commit, dispatch}, item) {
        try {
            let params = {
                page_size: 20,
                member_token: item.member_token,
                is_mobile: item.is_mobile ? 1 : 0, // 手機版帶 1，pc帶 0 或不帶 #107240
            }
            if(item.v && item.demo_ad_no) {
                params.demo_ad_no = item.demo_ad_no;
                params.v = item.v;
            }
            const data = await axios.get(API_URL , 
                            { params: params, responseType: "json" })
                            .catch((e)=> {
                                console.log(e)
                            });
            if (data.status === 200) {
                const api_data = data.data.data.articles;
                commit('setArticleCursor', api_data.slice(api_data.length - 2).find(obj => obj.type === "personal_article").id);

                commit('setMixArticleData', api_data);
            } else {
                return Promise.reject(data);
            }
        } catch(e) {
            console.log(e)
        }
    },
    async getMoreMixArticleData({commit, dispatch}, item) {
        try {
            const params = {
                page_size: 20,
                member_token: item.member_token,
                page: item.page,
                cursor: item.cursor,
                is_mobile: item.is_mobile ? 1 : 0,
            }
            const data = await axios.get(API_URL , 
                            { params: params, responseType: "json" })
                            .catch((e)=> {
                                console.log(e)
                            });
            if (data.status === 200) {
                const api_data = data.data.data.articles;
                commit('setArticleCursor', api_data.slice(api_data.length - 2).find(obj => obj.type === "personal_article").id);

                commit('setMoreMixArticleData', api_data);
            } else {
                return Promise.reject(data);
            }
        } catch(e) {
            console.log(e)
        }
    },
}

const API_URL = 'https://www.pixnet.net/mainpage/api/channel/getSuggestByModel';