import axios from "axios";
export const state = () => ({
    article_1201: [],
    article_1202: [],
    article_1203: [],
    article_1204: [],
    article_12: [],
    model_id_index: "",
    is_18up: false,
    adult_ranking: [],
    blogger_top_rank_model_id: ""
})
export const mutations = {
    setArticleData1201(state, article_data){
        state.article_1201 = article_data;
    },
    setArticleData1202(state, article_data){
        state.article_1202 = article_data;
    },
    setArticleData1203(state, article_data){
        state.article_1203 = article_data;
    },
    setArticleData1204(state, article_data){
        state.article_1204 = article_data;
    },
    setArticleData12(state, article_data){
        state.article_12 = article_data;
    },
    setMoreArticleData12(state, article_data){
        state.article_12 = state.article_12.concat(article_data)
    },
    setModelIdIndex(state, model_id) {
        state.model_id_index = model_id;
    },
    changeIs18UpStatus(state) {
        state.is_18up = true;
    },
    changeIsNot18UpStatus(state) {
        state.is_18up = false;
    },
    setBloggerModelId(state, model_id) {
        state.blogger_top_rank_model_id = model_id
    },
    setAdultRanking(state, ranking) {
        state.adult_ranking = ranking
    }
}
export const actions = {
    async getArticleData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestArticle2';
        const data = await axios.get(API_url , 
                        { params: params, responseType: "json" })
                        .catch((e)=> {
                            console.log(e)
                        });
        if (data.status === 200) {
            commit('setModelIdIndex', data.data.model_id);
            if (params.channel_id == 1201) {
                commit('setArticleData1201', data.data.data.articles);
            } else if (params.channel_id == 1202) {
                commit('setArticleData1202', data.data.data.articles);
            } else if (params.channel_id == 1203) {
                commit('setArticleData1203', data.data.data.articles);
            } else if (params.channel_id == 1204) {
                commit('setArticleData1204', data.data.data.articles);
            } else {
                console.log("false id")
            }
        } else {
            return Promise.reject(data);
        }
    },
    async getArticleDataWithFalcon({commit}, params){
        const API_url = 'https://falcon.pixnet.net/ad/adultindex';
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" })
            .catch((e)=> {
                console.log(e)
            });
        if(data.status === 200 && !data.error) {
            // commit('setModelIdIndex', data.data.model_id);
            if (params.channel_id == 1201) {
                commit('setArticleData1201', data.data.data ? data.data.data : []);
            } else if (params.channel_id == 1202) {
                commit('setArticleData1202', data.data.data ? data.data.data : []);
            } else if (params.channel_id == 1203) {
                commit('setArticleData1203', data.data.data ? data.data.data : []);
            } else if (params.channel_id == 1204) {
                commit('setArticleData1204', data.data.data ? data.data.data : []);
            } else if (params.channel_id == 12) {
                commit('setArticleData12', data.data.data ? data.data.data : []);
            } else {
                console.log("false id")
            }
        } else {
            return Promise.reject(data);
        }
    },
    async getMoreArticleDataWithFalcon({commit}, params){
        const API_url = 'https://falcon.pixnet.net/ad/adultindex';
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" })
            .catch((e)=> {
                console.log(e)
            });
        console.log(data)
        if(data.status === 200 && !data.error) {
            // commit('setModelIdIndex', data.data.model_id);
            if (params.channel_id == 1201) {
                commit('setArticleData1201', data.data.data);
            } else if (params.channel_id == 1202) {
                commit('setArticleData1202', data.data.data);
            } else if (params.channel_id == 1203) {
                commit('setArticleData1203', data.data.data);
            } else if (params.channel_id == 1204) {
                commit('setArticleData1204', data.data.data);
            } else if (params.channel_id == 12) {
                commit('setMoreArticleData12', data.data.data);
            } else {
                console.log("false id")
            }
        } else {
            return Promise.reject(data);
        }
    },
    async setMoreData({ commit }, dataParams) {
        if (dataParams.channelId == 1201) {
            commit('setArticleData1201', dataParams.article);
        } else if (dataParams.channelId == 1202) {
            commit('setArticleData1202', dataParams.article);
        } else if (dataParams.channelId == 1203) {
            commit('setArticleData1203', dataParams.article);
        } else if (dataParams.channelId == 1204) {
            commit('setArticleData1204', dataParams.article);
        } else if (dataParams.channelId == 12) {
            commit('setMoreArticleData12', dataParams.article);
        } else {
            console.log("false id")
        }
    },
    async getAdultRanking({commit}){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestBlogger';
        /* 文件： http://showdoc.cmh.com.tw:4999/web/#/8/92 */
        const params = { channel_id: 12, with_blog: 1, with_counter: 1, with_articles: 1, with_profile: 1 }
        const data = await axios.get(API_url , 
                        { params: params, responseType: "json" })
                        .catch((e)=> {
                            console.log(e)
                        });
        if (data.status === 200 && !data.error) {
            commit('setBloggerModelId', data.data.model_id);
            commit('setAdultRanking', data.data.data.slice(0, 5));
        } else {
            commit('setAdultRanking', []);
            return Promise.reject(data);
        }
    },
}