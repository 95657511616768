
import { mapMutations } from 'vuex'; 
export default {
    name: 'tab-container',
    data() {
        return {
            tab_index: [
                {
                    link: "/",
                    text: "首頁",
                    type: "index",
                    id: 1,
                    tab_name:"index"
                },
                {
                    link: "/channel/food",
                    // link_old: "https://www.pixnet.net/blog/articles/group/1",
                    text: "美食",
                    type: "food",
                    id: 2,
                    tab_name:"Channel-Food"
                },
                {
                    link: "/channel/movie",
                    // link_old: "https://www.pixnet.net/blog/articles/group/5",
                    text: "影視",
                    type: "movie",
                    id: 3,
                    tab_name:"Channel-Movie"
                },
                {
                    link: "/channel/travel",
                    // link_old: "https://www.pixnet.net/blog/articles/group/2",
                    text: "旅遊",
                    type: "travel",
                    id: 4,
                    tab_name:"Channel-Travel"
                },
                {
                    link_old: "https://1px.tw/p2GNu3Ez",
                    text: "🛒 styleMe",
                    type: "styleme",
                    id: 5,
                    tab_name:"Channel-style"
                },
                {
                    link: "/channel/family",
                    // link_old: "https://www.pixnet.net/blog/articles/group/4",
                    text: "親子",
                    type: "family",
                    id: 6,
                    tab_name:"Channel-Family"
                },
                {
                    link: "/channel/pet",
                    // link_old: "https://www.pixnet.net/blog/articles/category/3",
                    text: "寵物",
                    type: "pet",
                    id: 7,
                    tab_name:"Channel-Pet"
                },
                {
                    link: "/channel/3c",
                    // link_old: "https://www.pixnet.net/blog/articles/group/3",
                    text: "3C",
                    type: "3c",
                    id: 8,
                    tab_name:"Channel-3c"
                },
                {
                    link: "/channel/body-mind-spirit",
                    // link_old: "https://www.pixnet.net/blog/articles/group/9",
                    text: "身心靈",
                    type: "body-mind-spirit",
                    id: 9,
                    tab_name:"Channel-Body-Mind-Spirit"
                },
                {
                    link: "/channel/beauty",
                    // link_old: "https://www.pixnet.net/blog/articles/group/6",
                    text: "美妝",
                    type: "beauty",
                    id: 10,
                    tab_name:"Channel-Beauty"
                },
                {
                    link: "/channel/health",
                    // link_old: "https://www.pixnet.net/blog/articles/group/7",
                    text: "健康",
                    type: "health",
                    id: 11,
                    tab_name:"Channel-Health"
                },
                {
                    link: "/channel/career",
                    // link_old: "https://www.pixnet.net/blog/articles/category/9",
                    text: "職涯",
                    type: "career",
                    id: 12,
                    tab_name:"Channel-Career"
                },
                {
                    link: "/channel/home",
                    // link_old: "https://www.pixnet.net/blog/articles/group/10",
                    text: "住家",
                    type: "home",
                    id: 13,
                    tab_name:"Channel-Home"
                },
                {
                    link: "/channel/adult",
                    text: "私康",
                    type: "adult",
                    id: 14,
                    tab_name:"Channel-Adult",
                },
                // {
                //     // link: "/channel/home",
                //     link_old: "https://www.pixnet.net/blog/articles/group/10",
                //     text: "生活甜甜圈",
                //     type: "home",
                //     id: 14,
                //     tab_name:"Channel-Home2"
                // },
            ],
            showMobileActive: false,
            showAnimation: false,
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        tab_type() {
            return this.$store.state.tab.tab_type;
        },
        tab_id() {
            return this.$store.state.tab.tab_id;
        },
        tab_left() {
            return this.$store.state.tab.tab_highlight_style.left;
        },
        tab_width() {
            return this.$store.state.tab.tab_highlight_style.width;
        },
        route_name() {
            return this.$store.state.tab.route_name;
        },
        is_dark() {
            return false;
        },
        router_name() {
            return this.$route.name;
        },
    },
    methods: {
        ...mapMutations('tab', [
            'updateTabId',
            'updateTabType',
            'updateTabLeft',
            'updateTabWidth',
            'updateRouteName'
        ]),
        ...mapMutations('banner', [
            'cleanBannerData',
        ]),
        activeTab(type, id, tab_name) {
            if( this.tab_index[id-1].link ) {
                this.updateTabId(id);
                this.updateTabType(type);
                this.updateRouteName(tab_name);
                this.setTabHighLighter(id);
                this.cleanBannerData();
                this.showMobileActive = false;
            }
        },
        setTabHighLighter(id){
            setTimeout(()=>{
                const width = this.$refs.category[id - 1]?.clientWidth;
                const left = this.$refs.category[id - 1]?.getBoundingClientRect().left;
                this.updateTabLeft(left);
                this.updateTabWidth(width);
            },300)
            
        },
        resizeTabHighLighter(id){
            setTimeout(()=>{
                const left = this.$refs.category[id - 1].getBoundingClientRect().left;
                this.updateTabLeft(left);
            }, 100)
            // same as this.$store.commit('tab/updateTabLeft', left );
            
        },
        setShowAnimation(){
            setTimeout(()=>{
                this.showAnimation = true;
            }, 300)
        },
        executeWhenResize(e) {
            this.resizeTabHighLighter(this.tab_id);
        },
        toggleMobileTab() {
            this.showMobileActive = !this.showMobileActive;
        },
        useRouteNameToFindIdFromArr(name, arr){
            arr.forEach(e => {
                if(e.tab_name == name) {
                    this.setTabHighLighter(e.id)
                    this.updateTabId(e.id);
                }
            });
        },
        showWhenMemberLogin(need_login) {
            if(need_login) {
                if(this.is_login && this.member_data.member_is_adult) {
                    return true;
                } else if (this.is_login && this.member_data.member_is_adult == null) {
                    return true;
                } else {
                    return false
                }
            } else {
                return true;
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.executeWhenResize);
        this.updateRouteName(this.$route.name);
        this.useRouteNameToFindIdFromArr(this.$route.name, this.tab_index)
        this.setShowAnimation();
    },
    destroyed() {
        window.removeEventListener('resize', this.executeWhenResize);
    },
}
